/**
 * CSS for Moniqa-Display
 * Generated by Wakamai Fondue - https://wakamaifondue.com
 * by Roel Nieskens/PixelAmbacht - https://pixelambacht.nl
 */

/* Set custom properties for each layout feature */
:root {
    --moniqa-display-aalt: "aalt" off;
    --moniqa-display-dlig: "dlig" off;
    --moniqa-display-frac: "frac" off;
    --moniqa-display-ordn: "ordn" off;
    --moniqa-display-salt: "salt" off;
    --moniqa-display-ss01: "ss01" off;
}

/* If class is applied, update custom property and
   apply modern font-variant-* when supported */
.moniqa-display-aalt {
    --moniqa-display-aalt: "aalt" on;
}

.moniqa-display-dlig {
    --moniqa-display-dlig: "dlig" on;
}

@supports (font-variant-ligatures: discretionary-ligatures) {
    .moniqa-display-dlig {
        --moniqa-display-dlig: "____";
        font-variant-ligatures: discretionary-ligatures;
    }
}

.moniqa-display-frac {
    --moniqa-display-frac: "frac" on;
}

@supports (font-variant-numeric: diagonal-fractions) {
    .moniqa-display-frac {
        --moniqa-display-frac: "____";
        font-variant-numeric: diagonal-fractions;
    }
}

.moniqa-display-ordn {
    --moniqa-display-ordn: "ordn" on;
}

@supports (font-variant-numeric: ordinal) {
    .moniqa-display-ordn {
        --moniqa-display-ordn: "____";
        font-variant-numeric: ordinal;
    }
}

.moniqa-display-salt {
    --moniqa-display-salt: "salt" on;
}

.moniqa-display-ss01 {
    --moniqa-display-ss01: "ss01" on;
}

/* Apply current state of all custom properties
   whenever a class is being applied */
.moniqa-display-aalt,
.moniqa-display-dlig,
.moniqa-display-frac,
.moniqa-display-ordn,
.moniqa-display-salt,
.moniqa-display-ss01 {
    font-feature-settings: var(--moniqa-display-aalt), var(--moniqa-display-dlig), var(--moniqa-display-frac), var(--moniqa-display-ordn), var(--moniqa-display-salt), var(--moniqa-display-ss01);
}

